import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import { ChevronLeft, ChevronRight, Search } from "lucide-react";
import SearchIcon from "../assets/EventsPage/SearchIcon.png";
import EventPic1 from "../assets/EventsPage/EventsPic1.png";
import EventPic2 from "../assets/EventsPage/EventPic2.png";
import Arrow from "../assets/EventsPage/Arrow.png";
import EventPic3 from "../assets/EventsPage/EventPic3.png";
import Event1 from "./images/Frame 1707480486.png";
import Event2 from "./images/unsplash_EVgsAbL51Rk (1).png";
import Event3 from "./images/unsplash_dEjMN6JXcj8.png";
import Event4 from "../assets/EventsPage/mht-cet-banner-desktop.png";
import { useNavigate } from "react-router-dom";

const EventsPageFix = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const animation1 = useSpring({
    transform: inView1 ? "translateY(0)" : "translateY(100px)",
    opacity: inView1 ? 1 : 0,
    config: { tension: 200, friction: 20 },
  });

  const animation2 = useSpring({
    transform: inView2 ? "translateY(0)" : "translateY(100px)",
    opacity: inView2 ? 1 : 0,
    config: { tension: 200, friction: 20 },
  });

  const animation3 = useSpring({
    transform: inView3 ? "translateY(0)" : "translateY(100px)",
    opacity: inView3 ? 1 : 0,
    config: { tension: 200, friction: 20 },
  });

  const upcomingEvents = [
    {
      id: 3,
      image: Event4,
      date: "March 8,2025",
      time: "9 am",
      title: "MHT CET Crash Course",
      location: "@KalyanCenter",
    },
    {
      id: 1,
      image: Event1,
      date: "December 26,2024",
      time: "",
      title: "Strengthen Your Maths Skills And Overcome Fear Of Numbers",
      location: "@KalyanCenter",
    },
    {
      id: 2,
      image: Event3,
      date: "December 26,2024",
      time: "",
      title: "Strengthen Your Maths Skills And Overcome Fear Of Numbers",
      location: "@KalyanCenter",
    },
  ];

  const pastEvents = [
    {
      id: 3,
      image: EventPic3,
      date: "August 15, 2024",
      time: "16:00",
      title: "Career Guidance Workshop",
      location: "@KalyanCenter",
    },
    {
      id: 4,
      image: EventPic2,
      date: "July 25, 2024",
      time: "15:30",
      title: "Mock Test Series for NEET Aspirants",
      location: "@KalyanCenter",
    },
  ];

  const refs = [ref1, ref2, ref3];
  const animations = [animation1, animation2, animation3];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % upcomingEvents.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prev) => (prev - 1 + upcomingEvents.length) % upcomingEvents.length
    );
  };

  useEffect(() => {
    const timer = setInterval(nextSlide, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="w-full min-h-screen">
      {/* Header Section */}
      <div className="font-roboto flex flex-col sm:flex-row justify-between w-[90%] mx-auto mt-4 sm:mt-8 lg:mt-16 space-y-4 sm:space-y-0">
        <div>
          <h1 className="text-lg sm:text-3xl lg:text-[48px] text-[#002F48] font-semibold">
            Upcoming Events
          </h1>
          <p className="w-[30%] sm:w-[40%] border-t-[3px] border-[#f77f00]"></p>
        </div>
        <div className="flex items-center justify-center w-full sm:w-[30%] h-[40px] border border-black rounded-[30px] bg-white">
          <Search className="w-5 h-5 ml-3 text-gray-500" />
          <input
            className="w-[80%] px-3 py-2 border-none outline-none text-sm placeholder:text-[#191919] placeholder:text-sm placeholder:font-medium"
            type="search"
            placeholder="SEARCH EVENTS"
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
      </div>

      {/* Carousel Section */}
      <div className="w-full px-4 sm:w-[90%] sm:px-0 mx-auto">
        <div className="relative w-full h-[250px] sm:h-[400px] lg:h-[626px] mt-6 sm:mt-8 lg:mt-10">
          <div className="relative w-full h-full">
            {upcomingEvents.map((event, index) => (
              <div
                key={event.id}
                className={`absolute top-0 left-0 w-full h-full transition-opacity duration-500 ${
                  index === currentSlide ? "opacity-100 z-10" : "opacity-0"
                }`}
              >
                {/* Image Container */}
                <div className="relative w-full h-full overflow-hidden bg-gray-100">
                  <img
                    className="w-full h-full sm:object-fill lg:object-fill md:object-contain"
                    src={event.image}
                    alt={event.title}
                    style={{
                      objectPosition: "center center",
                    }}
                  />
                  <div className="absolute inset-0 bg-black/50" />
                </div>

                {/* Content Container */}
                <div className="absolute w-[94%] sm:w-[90%] lg:w-[80%] left-[3%] sm:left-[5%] lg:left-[10%] bottom-[10%] text-white">
                  <div className="flex gap-1 sm:gap-2 lg:gap-4 items-center text-[7px] sm:text-base lg:text-[24px]">
                    <span className="font-bold ">Starts from</span>
                    <p className="">{event.date}</p>
                  </div>
                  <h1 className="mt-2 sm:mt-4 lg:mt-6 w-[70%] sm:w-[70%] text-[10px]  sm:text-xl lg:text-[32px] font-bold">
                    {event.title}
                  </h1>
                  <p className="mt-1 sm:mt-2 lg:mt-4 text-[7px]  sm:text-base lg:text-[24px]">
                    {event.location}
                  </p>
                  <button
                    className="mt-2 sm:mt-4 lg:mt-6 w-[45px] sm:w-[100px] lg:w-[157px] h-[13px] sm:h-[30px] lg:h-[46px] text-[6px] sm:text-sm lg:text-[20px] text-white bg-[#F67F00] rounded-[40px]"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate(`/event/readMore/${event.id}`);
                    }}
                  >
                    Read More
                  </button>
                </div>
              </div>
            ))}

            {/* Carousel Controls */}
            <div className="absolute top-1/2 left-[2%] transform -translate-y-1/2 z-20">
              <button
                onClick={prevSlide}
                className="w-6 h-6 sm:w-8 sm:h-8 lg:w-12 lg:h-12 bg-white/25 hover:bg-white rounded-full shadow-lg transition-colors grid place-items-center"
              >
                <ChevronLeft
                  className="w-4 h-4 sm:w-5 sm:h-5 lg:w-6 lg:h-6 text-[#002F48]"
                  strokeWidth={2.5}
                />
              </button>
            </div>
            <div className="absolute top-1/2 right-[2%] transform -translate-y-1/2 z-20">
              <button
                onClick={nextSlide}
                className="w-6 h-6 sm:w-8 sm:h-8 lg:w-12 lg:h-12 bg-white/25 hover:bg-white rounded-full shadow-lg transition-colors grid place-items-center"
              >
                <ChevronRight
                  className="w-4 h-4 sm:w-5 sm:h-5 lg:w-6 lg:h-6 text-[#002F48]"
                  strokeWidth={2.5}
                />
              </button>
            </div>

            {/* Carousel Indicators */}
            <div className="absolute bottom-[5%] left-1/2 transform -translate-x-1/2 flex gap-2 z-20">
              {upcomingEvents.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                  className={`w-2 h-2 sm:w-2.5 sm:h-2.5 lg:w-3 lg:h-3 rounded-full transition-colors ${
                    index === currentSlide ? "bg-[#F67F00]" : "bg-white/50"
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Past Events Section */}
      <div className="font-roboto w-[90%] mx-auto mt-12 sm:mt-16 lg:mt-24">
        <div className="mb-6 sm:mb-10 lg:mb-16">
          <h1 className="text-lg sm:text-3xl lg:text-[48px] text-[#002F48] font-semibold">
            Past Events
          </h1>
          <p className="w-[20%] sm:w-[25%] mt-2 border-t-[3px] border-[#f77f00]"></p>
        </div>

        {pastEvents.map((event, index) => (
          <animated.div
            key={event.id}
            ref={refs[index]}
            style={animations[index]}
            className="flex flex-col sm:flex-row gap-4 sm:gap-8 lg:gap-12 mb-6 sm:mb-12 lg:mb-20 border-2 sm:border-none rounded-[5px] sm:rounded-none p-3 sm:p-0"
          >
            <div className="w-full sm:w-[40%] h-[180px] sm:h-[280px] lg:h-[350px] flex-shrink-0">
              <img
                className="w-full h-full object-cover rounded-[15px] sm:rounded-none"
                src={event.image}
                alt={event.title}
              />
            </div>
            <div className="flex flex-col justify-between w-full sm:w-[60%]">
              <div>
                <div className="flex gap-2 sm:gap-3 lg:gap-4 text-[10px] sm:text-base lg:text-[24px] text-[#424242] items-center">
                  <p>{event.date}</p>
                  <p className="border-l-[2px] border-[#B2B1B1] h-[15px] sm:h-[20px] lg:h-[30px]"></p>
                  <p>{event.time}</p>
                </div>
                <h1 className="mt-2 sm:mt-3 lg:mt-5 max-w-[90%] text-[13px] sm:text-xl lg:text-[32px] text-[#002F48] font-bold">
                  {event.title}
                </h1>
                <p className="mt-2 sm:mt-3 lg:mt-5 text-[10px] sm:text-base lg:text-[24px] text-[#424242]">
                  {event.location}
                </p>
              </div>
              <div className="mt-4 sm:mt-6 lg:mt-0">
                <div className="flex items-center gap-1 sm:gap-2 lg:gap-4 mb-1 sm:mb-2">
                  <p className="text-[10px] sm:text-base lg:text-[20px] text-[#002F48] font-bold">
                    View event details
                  </p>
                  <img
                    className="w-[14px] h-[10px] sm:w-[16px] sm:h-[12px] lg:w-auto lg:h-auto"
                    src={Arrow}
                    alt="Arrow"
                  />
                </div>
                <p className="w-[30%] border-t-[2px] border-[#002F48]"></p>
              </div>
            </div>
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default EventsPageFix;
