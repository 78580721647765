import React from "react";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import Bg1 from "../../assets/EventsReadMore/Bg1.png";
import Arrow from "../../assets/EventsReadMore/RegisterNow Arrow.png";
import Event1 from "../../assets/EventsReadMore/Event1.png";
import { useParams } from "react-router-dom";
import SscEvent from "./SscEvent/SscEvent";
import MathsWorkshop from "./MathsEvent/MathsEvent";
import MathsWorkshopFix from "./MathsEvent/MathsEventFix";
import MhtCetPage from "./MhtCetEvent/MhtCetPage";

const EventReadMore = () => {
  const { id } = useParams();
  console.log(id);

  if (id == 1) {
    return <MathsWorkshopFix eventId={id} />;
  } else if (id == 2) {
    return <SscEvent eventId={id} />;
  } else if (id == 3) {
    return <MhtCetPage />;
  } else {
    return <h1>Hello</h1>;
  }
};

export default EventReadMore;
