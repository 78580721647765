import React from "react";
import Logo from "../images/_2970566654880.png";

const RegistrationForm = () => {
  const RequiredLabel = ({ text }) => (
    <div className="flex items-start gap-0.5">
      <span className="text-gray-700 text-base md:text-xl font-semibold">
        {text}
      </span>
      <span className="text-red-500">*</span>
      <span className="text-gray-700 text-sm md:text-base">:</span>
    </div>
  );

  const fields = [
    {
      label: "Email ID ",
      type: "email",
      placeholder: "Enter a valid Email ID",
    },
    {
      label: "Mobile No ",
      type: "tel",
      placeholder: "Enter Your Mobile No",
    },
    {
      label: "Standard ",
      type: "text",
      placeholder: "Enter your Standard",
    },
    {
      label: "School/College ",
      type: "text",
      placeholder: "Enter your School/ College Name",
    },
  ];

  return (
    <div className="min-h-screen bg-sky-50 flex flex-col items-center p-4 md:p-8">
      <div className="w-full max-w-[82%]">
        <div className="flex items-center gap-2 mb-6">
          <img
            src={Logo}
            alt="SE Education Logo"
            className="w-10 h-10 md:w-14 md:h-14"
          />
          <h1 className="text-xl md:text-4xl font-bold text-center w-full">
            Registration Form
          </h1>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-4 md:p-8">
          <h2 className="text-xl font-roboto font-bold md:text-2xl md:font-bold text-center mb-6 md:mb-8 text-gray-800">
            Please fill the form below to join the workshop
          </h2>

          <form className="space-y-6">
            <div className="grid gap-6">
              <div className="grid grid-cols-1 md:grid-cols-[150px_1fr] gap-2 md:gap-4 md:items-center">
                <RequiredLabel text="Full Name " />
                <div className="flex flex-col sm:flex-row gap-4">
                  <input
                    type="text"
                    placeholder="First Name"
                    required
                    className="w-full p-2 border-2 rounded-xl border-custom-blue focus:outline-none focus:ring-2 focus:ring-blue-200 text-sm md:text-base"
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    required
                    className="w-full p-2 border-2 rounded-xl border-custom-blue focus:outline-none focus:ring-2 focus:ring-blue-200 text-sm md:text-base"
                  />
                </div>
              </div>

              {fields.map((field) => (
                <div
                  key={field.label}
                  className="grid grid-cols-1 md:grid-cols-[150px_1fr] gap-2 md:gap-4 md:items-center"
                >
                  <RequiredLabel text={field.label} />
                  <input
                    type={field.type}
                    placeholder={field.placeholder}
                    required
                    className="w-full p-2 border-2 rounded-xl border-custom-blue focus:outline-none focus:ring-2 focus:ring-blue-200 text-sm md:text-base"
                  />
                </div>
              ))}

              <div className="grid grid-cols-1 md:grid-cols-[150px_1fr] gap-2 md:gap-4 md:items-start">
                <RequiredLabel text="Address " />
                <textarea
                  placeholder="Enter Address"
                  required
                  rows={4}
                  className="w-full p-2 border-2 rounded-md border-custom-blue focus:outline-none focus:ring-2 focus:ring-blue-200 text-sm md:text-base"
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-[150px_1fr] gap-2 md:gap-4 md:items-center">
                <RequiredLabel text="Gender " />
                <div className="flex flex-wrap gap-4 md:gap-6">
                  {["Female", "Male", "Other"].map((option) => (
                    <label
                      key={option}
                      className="flex items-center text-custom-blue"
                    >
                      <input
                        type="radio"
                        name="gender"
                        value={option.toLowerCase()}
                        required
                        className="mr-2 w-5 h-5"
                      />
                      <span className="text-sm md:text-base">{option}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-4">
              <label className="flex items-start gap-2 ">
                <input
                  type="checkbox"
                  required
                  className="mt-1 rounded border-custom-blue w-4 h-4"
                />
                <span className="text-xs font-semibold md:text-base text-gray-600">
                  I confirm to allow the instructor call or Message to proceed
                  with registration process.
                </span>
              </label>
            </div>

            <div className="flex justify-center mt-6">
              <button
                type="submit"
                className="w-full md:w-80 bg-orange-500 text-white py-2 md:py-3 rounded-md hover:bg-orange-600 transition-colors text-sm md:text-base"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
