import React from "react";
import { useParams } from "react-router-dom";
import coursesData from "./courses.json";
import backgroundImage from "./bg1.png"; // Background image
import TutorSection from "./TutorSection";
import TestimonialCard from "../Testimonial/Testimon";

function CourseDetail() {
  const { id } = useParams();
  const course = coursesData.find((course) => course.id === id);

  if (!course) {
    return <div>Course not found</div>;
  }

  return (
    <div className="bg-white min-h-screen flex flex-col items-center py-3">
      {/* First Main Content Container with Background Image */}
      <div
        className="relative w-full max-w-[1358px] h-auto rounded-[20px] p-6 md:p-10 shadow-lg mb-6 mt-0"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Title Section */}
        <div className="text-gray-700 mb-6">
          {/* Title and Subtitle Section */}
          <div className="sm:w-1/2">
            <h3 className="text-xs md:text-sm font-semibold mb-2 mt-3 ">
              Courses - School - {course.title}
            </h3>
            <h2 className="text-2xl md:text-4xl font-bold mb-4 mt-7  text-[#002F48] leading-tight text-shadow-lg">
              {course.title}
            </h2>
          </div>

          {/* Description */}
          <p className="text-sm md:text-lg text-[#002F48]">
            {course.description}
          </p>
        </div>

        {/* Bouncing Ball Animation */}
        <div className="absolute top-4 right-4 flex flex-col items-center">
          <div
            className="w-14 h-14 lg:w-20 lg:h-20 bg-[#efedec] rounded-full animate-bounce m-5 text-center lg:pt-45"
            aria-label="Help Ball"
            style={{fontSize: "30px", alignContent: "center"}}
          >
            ?
          </div>
          <div className="w-10 h-2 bg-black opacity-30 rounded-full animate-shadow-bounce mt-[-10px]"></div>
        </div>

        {/* Features Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
          {course.features.map((feature, index) => (
            <div
              key={index}
              className="bg-[#4D718B] text-white p-3 rounded-full  font-medium text-sm md:text-base pl-5"
            >
              {feature}
            </div>
          ))}
        </div>

        {/* Enroll Button */}
        <div className="flex mt-8">
          <button className="bg-[#F68C36] hover:bg-[#e07b2b] text-white font-bold py-3 px-8 rounded-full shadow-md transform hover:scale-105 transition-all">
            Enroll Now
          </button>
        </div>
      </div>

      {/* Second Course Detail Container */}
      <div className="bg-[#DCE6F0] w-full max-w-[1358px] rounded-[20px] p-6 md:p-10 shadow-lg">
        {/* Heading for Course Details - Center Aligned */}
        <h2 className="text-lg md:text-2xl font-bold mb-4 text-[#173E5E] text-center">
          Course Details
        </h2>

        {/* Course Detail Title and Description */}
        <h2 className="text-lg md:text-2xl lg:text-3xl font-semibold mb-4 text-[#1F4C6B]">
          {course.details.title}
        </h2>
        <p className="text-sm md:text-lg lg:text-xl text-gray-700 mb-4">
          {course.details.description}
        </p>

        {/* Key Features Section */}
        <h3 className="text-md md:text-lg font-bold mb-3 text-[#1F4C6B]">
          Key Features
        </h3>
        <ul className="list-disc ml-5 text-sm md:text-base lg:text-lg text-gray-800 mb-6">
          {course.details.keyFeatures.map((keyFeature, index) => {
            // Split the text into bold heading and description based on the colon
            const [heading, description] = keyFeature.split(":");
            return (
              <li key={index} className="mb-2">
                <strong>{heading}:</strong> {description}
              </li>
            );
          })}
        </ul>

        {/* Bottom Text */}
        <p className="text-base md:text-lg font-medium text-gray-800">
          <strong>{course.details.text}</strong>
        </p>
      </div>

      <br />
      <br />
      <br />

      {/* Tutor Section */}
      <div className="bg-[#d8e5ed] w-full text-center pt-7">
        <h1 className=" text-5xl font-roboto font-semibold text-[#406376] mb-0">
          Testimonials
        </h1>
      </div>
      <TestimonialCard />
      <TutorSection course={course} />
    </div>
  );
}

export default CourseDetail;
