import React from "react";
import "./Testimon.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import pic1 from "./Pic1.png";
import pic2 from "./left.png";
import pic3 from "./right.png";

const TestimonialCard = () => {
  return (
    <>
      <div className="cont relative bg-[#D8E5ED] w-full">
        <div className="cards w-80 ">
          <div className="card relative">
            <div className="absolute top-10 rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xs leading-5 lg:text-xl font-roboto text-[#002F48] font-normal  lg:leading-8  w-72 absolute lg:left-48 lg:block pl-10 pt-16 lg:w-[890px] mx-auto">
              "Studying IIT-JEE under SG Education has been a game-changer! The
              teaching style is clear, practical, and focused on building a deep
              understanding of concepts. Latesh Sir’s passion for Physics and
              Maths makes every class engaging and inspiring. The personal
              attention and customized study plans helped me improve
              significantly. Complex topics became easy to grasp, and the
              regular practice sessions boosted my confidence. I always felt
              motivated and well-prepared for exams. SG Education is truly
              dedicated to students' success, and I'm grateful to be a part of
              it!"
              <div className="absolute -bottom-28 right-[10%] lg:right-[40%] flex flex-col justify-center items-center">
                {/* <img src={pic1} className="" alt=" " width={117} /> */}
                <h3 className="font-bold font-roboto text-2xl text-[#002F48]">
                  Gaurav Ghude
                </h3>
                <p className="font-normal font-roboto text-2xl text-[#002F48]">
                  (JEE Batch 2023-24)
                </p>
              </div>
            </p>

            {/* double inverted comma */}
            <div>
              <img
                src={pic2}
                alt=""
                className="absolute top-14 left-3 lg:top-20  lg:left-36 left"
              />
              <img
                src={pic3}
                alt=""
                className="absolute bottom-40 right-7  lg:bottom-40 lg:right-28 right"
                width={79}
                height={189}
              />
            </div>
          </div>
          <div className="card relative">
            <div className="absolute top-10 rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xs leading-5 lg:text-xl font-roboto text-[#002F48] font-normal  lg:leading-8  w-72 absolute lg:left-48 lg:block pl-10 pt-16 lg:w-[890px] mx-auto">
              "My Daughter Shruti doing her studies under Latesh sir guidance &
              preparing for entrance exams, the teaching atmosphere is very nice
              at SG, they make students to engage in studies in positive way. My
              Daughter improves a lot in studies after joining SG Class."
              <div className="absolute -bottom-28 right-[10%] lg:right-[40%] flex flex-col justify-center items-center">
                {/* <img src={pic1} className="" alt=" " width={117} /> */}
                <h3 className="font-bold font-roboto text-2xl text-[#002F48]">
                  Shruti Verma Parent
                </h3>
                <p className="font-normal font-roboto text-2xl text-[#002F48]">
                  (8th Class)
                </p>
              </div>
            </p>

            {/* double inverted comma */}
            <div>
              <img
                src={pic2}
                alt=""
                className="absolute top-14 left-3 lg:top-20  lg:left-36 left"
              />
              <img
                src={pic3}
                alt=""
                className="absolute bottom-40 right-7  lg:bottom-40 lg:right-28 right"
                width={79}
                height={189}
              />
            </div>
          </div>
          <div className="card relative">
            <div className="absolute top-10 rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xs leading-5 lg:text-xl font-roboto text-[#002F48] font-normal  lg:leading-8  w-72 absolute lg:left-48 lg:block pl-10 pt-16 lg:w-[890px] mx-auto">
              "SG class stands out as a top-notch tutoring center. The Teachers
              & staƯ is great. SG class provide education that focuses on
              achieving results along, with oƯering a range of activities that
              helps in boosting student development and progress. I have scored
              98%tile & had admission in reputed engineering college. Thank you
              SG Class & Team."
              <div className="absolute -bottom-28 right-[10%] lg:right-[40%] flex flex-col justify-center items-center">
                {/* <img src={pic1} className="" alt=" " width={117} /> */}
                <h3 className="font-bold font-roboto text-2xl text-[#002F48]">
                  Sneha Bhundhere
                </h3>
                <p className="font-normal font-roboto text-2xl text-[#002F48]">
                  (MHT-CET)
                </p>
              </div>
            </p>

            {/* double inverted comma */}
            <div>
              <img
                src={pic2}
                alt=""
                className="absolute top-14 left-3 lg:top-20  lg:left-36 left"
              />
              <img
                src={pic3}
                alt=""
                className="absolute bottom-40 right-7  lg:bottom-40 lg:right-28 right"
                width={79}
                height={189}
              />
            </div>
          </div>
          <div className="card relative">
            <div className="absolute top-10 rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xs leading-5 lg:text-xl font-roboto text-[#002F48] font-normal  lg:leading-8  w-72 absolute lg:left-48 lg:block pl-10 pt-16 lg:w-[890px] mx-auto">
              "I joined SG Education to strengthen my basics in Physics and
              Chemistry. The structured approach, weekly mock tests, and
              personalized feedback helped me understand my weak areas and
              improve them. Latesh Sir’s guidance and Varsha Ma’am’s
              explanations in Biology were crucial for my success. Thank you, SG
              Education!"
              <div className="absolute -bottom-28 right-[10%] lg:right-[40%] flex flex-col justify-center items-center">
                {/* <img src={pic1} className="" alt=" " width={117} /> */}
                <h3 className="font-bold font-roboto text-2xl text-[#002F48]">
                  Aryan Deshmukh
                </h3>
                <p className="font-normal font-roboto text-2xl text-[#002F48]">
                  (MHT-CET)
                </p>
              </div>
            </p>

            {/* double inverted comma */}
            <div>
              <img
                src={pic2}
                alt=""
                className="absolute top-14 left-3 lg:top-20  lg:left-36 left"
              />
              <img
                src={pic3}
                alt=""
                className="absolute bottom-40 right-7  lg:bottom-40 lg:right-28 right"
                width={79}
                height={189}
              />
            </div>
          </div>
          <div className="card relative">
            <div className="absolute top-10 rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xs leading-5 lg:text-xl font-roboto text-[#002F48] font-normal  lg:leading-8  w-72 absolute lg:left-48 lg:block pl-10 pt-16 lg:w-[890px] mx-auto">
              "SG class stands out as a top-notch tutoring center. The Teachers
              & staƯ is great. SG class provide education that focuses on
              achieving results along, with oƯering a range of activities that
              helps in boosting student development and progress. I have scored
              98%tile & had admission in reputed engineering college. Thank you
              SG Class & Team."
              <div className="absolute -bottom-28 right-[10%] lg:right-[40%] flex flex-col justify-center items-center">
                {/* <img src={pic1} className="" alt=" " width={117} /> */}
                <h3 className="font-bold font-roboto text-2xl text-[#002F48]">
                  Sneha Bhundhere
                </h3>
                <p className="font-normal font-roboto text-2xl text-[#002F48]">
                  (MHT-CET)
                </p>
              </div>
            </p>

            {/* double inverted comma */}
            <div>
              <img
                src={pic2}
                alt=""
                className="absolute top-14 left-3 lg:top-20  lg:left-36 left"
              />
              <img
                src={pic3}
                alt=""
                className="absolute bottom-40 right-7  lg:bottom-40 lg:right-28 right"
                width={79}
                height={189}
              />
            </div>
          </div>
          <div className="card relative">
            <div className="absolute top-10 rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xs leading-5 lg:text-xl font-roboto text-[#002F48] font-normal  lg:leading-8  w-72 absolute lg:left-48 lg:block pl-10 pt-16 lg:w-[890px] mx-auto">
              "SG Education has been a fantastic experience for NEET prep! The
              classes are thorough and well-structured, making even complex
              Biology and Chemistry topics easy to understand. I feel so much
              more confident now!"
              <div className="absolute -bottom-28 right-[10%] lg:right-[40%] flex flex-col justify-center items-center">
                {/* <img src={pic1} className="" alt=" " width={117} /> */}
                <h3 className="font-bold font-roboto text-2xl text-[#002F48]">
                  Arya Mahajan
                </h3>
                <p className="font-normal font-roboto text-2xl text-[#002F48]">
                  (NEET)
                </p>
              </div>
            </p>

            {/* double inverted comma */}
            <div>
              <img
                src={pic2}
                alt=""
                className="absolute top-14 left-3 lg:top-20  lg:left-36 left"
              />
              <img
                src={pic3}
                alt=""
                className="absolute bottom-40 right-7  lg:bottom-40 lg:right-28 right"
                width={79}
                height={189}
              />
            </div>
          </div>
          <div className="card relative">
            <div className="absolute top-10 rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xs leading-5 lg:text-xl font-roboto text-[#002F48] font-normal  lg:leading-8  w-72 absolute lg:left-48 lg:block pl-10 pt-16 lg:w-[890px] mx-auto">
              "SG Education provided the right environment for me to focus on my
              studies. The comprehensive study materials and the strategic
              revision sessions were game changers. The faculty’s support and
              attention to detail made learning easy and eƯective. I couldn't
              have achieved this without their help."
              <div className="absolute -bottom-28 right-[10%] lg:right-[40%] flex flex-col justify-center items-center">
                {/* <img src={pic1} className="" alt=" " width={117} /> */}
                <h3 className="font-bold font-roboto text-2xl text-[#002F48]">
                  Meera Shah
                </h3>
                <p className="font-normal font-roboto text-2xl text-[#002F48]">
                  (MHT-CET)
                </p>
              </div>
            </p>

            {/* double inverted comma */}
            <div>
              <img
                src={pic2}
                alt=""
                className="absolute top-14 left-3 lg:top-20  lg:left-36 left"
              />
              <img
                src={pic3}
                alt=""
                className="absolute bottom-40 right-7  lg:bottom-40 lg:right-28 right"
                width={79}
                height={189}
              />
            </div>
          </div>
          <div className="card relative">
            <div className="absolute top-10 rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xs leading-5 lg:text-xl font-roboto text-[#002F48] font-normal  lg:leading-8  w-72 absolute lg:left-48 lg:block pl-10 pt-16 lg:w-[890px] mx-auto">
              "The best part about SG Education was the focused, student-centric
              approach. The mock tests simulated the actual exam pattern and
              boosted my speed and accuracy. Latesh Sir’s dedication to each
              student’s success motivated me to push my limits. I highly
              recommend SG Education!"
              <div className="absolute -bottom-28 right-[10%] lg:right-[40%] flex flex-col justify-center items-center">
                {/* <img src={pic1} className="" alt=" " width={117} /> */}
                <h3 className="font-bold font-roboto text-2xl text-[#002F48]">
                  Priya Nair
                </h3>
                <p className="font-normal font-roboto text-2xl text-[#002F48]">
                  (MHT-CET)
                </p>
              </div>
            </p>

            {/* double inverted comma */}
            <div>
              <img
                src={pic2}
                alt=""
                className="absolute top-14 left-3 lg:top-20  lg:left-36 left"
              />
              <img
                src={pic3}
                alt=""
                className="absolute bottom-40 right-7  lg:bottom-40 lg:right-28 right"
                width={79}
                height={189}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialCard;
