import React, { useState } from "react";
import bannerImg from "./assets/bannerImg.png";
import bannermobileImg from "./assets/bannermobileImg.png";
import whyChoosebannerImg from "./assets/whyChooseBanner.png";
import whyChoosebannerImgMob from "./assets/whyChoosebannerImgMob.png";
import whyChoosemobilebannerImg from "./assets/whyChooseMobileBanner.png";
import whyChooseDesktopbannerImg from "./assets/whyChooseDesktopbannerImg.png";
import expertFaculty from "./assets/expertLogo.png";
import comprehensiveCoverage from "./assets/comprehensive.png";
import dailyMockTest from "./assets/dailyMock.png";
import stateOfArt from "./assets/stateofart.png";
import personalizedAttention from "./assets/personalized.png";
import physics from "./assets/physics.png";
import chemistry from "./assets/chemistry.png";
import maths from "./assets/maths.png";
import weOfferHeading1 from "./assets/weOfferHeading1.png";
import weOfferHeading2 from "./assets/weOfferHeading2.png";
import weOfferHeading3 from "./assets/weOfferHeading3.png";
import GauravGhude from "./assets/GauravGhude.png";
import KunalChoudhary from "./assets/KunalChoudhary.png";
import batchBanner from "./assets/batchesBanner.png";
import batchesdetail1 from "./assets/batchesdetail1.png";
import batchesdetail2 from "./assets/batchesdetail2.png";
import batchesdetaildesktop from "./assets/batchesdetaildesktop.png";
import FAQBanner from "./assets/faqBanner.png";
import FAQBannerDesktop from "./assets/faqBannerDesktop.png";
import timingBtn from "./assets/timingBtn.png";
import timingBtnDesktop from "./assets/timingBtnDesktop.png";
import SFBanner from "./assets/specialfeaturesbanner.png";
import SFBannerDesktop from "./assets/SFBannerDesktop.png";
import SFBg1 from "./assets/specialfeaturebg1.png";
import SFBg2 from "./assets/specialfeaturebg2.png";
import featureCount1 from "./assets/featurecount1.png";
import featureCount2 from "./assets/featurecount2.png";
import featureCount3 from "./assets/featurecount3.png";
import { useNavigate } from "react-router-dom";
import "./MhtCetPage.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function MhtCetPage() {
  const [openIndex, setOpenIndex] = useState(0);
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 760,
        settings: "slick",
      },
      {
        breakpoint: 2560,
        settings: "unslick",
      },
    ],
  };

  const faqData = [
    {
      question: "What is the fee for the crash course?",
      answer: "Our course is priced competitively. Call us for details.",
    },
    {
      question: "Will I get study materials?",
      answer: "Yes, comprehensive study materials will be provided.",
    },
    {
      question: "How are mock tests conducted?",
      answer: "Mock tests are conducted online and are timed.",
    },
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="overflow-hidden">
      <div className="banner bg-[linear-gradient(to_right,_#FFB532,_#FCA11F,_#F88C0D,_#F78606,_#F67F00)] text-white flex justify-between w-full pl-[5%]">
        <div className="bannerData w-full mt-[10%] 2xl:mt-[6%]">
          <h1 className="w-4/5 font-bold text-[1.5rem] leading-[120%] text-shadow md:text-[1.8rem] lg:text-[2.2rem] xl:text-[3rem] 2xl:text-[4rem]">
            Crack MHT CET 2025 with SG's Expert-Led Crash Course
          </h1>
          <div className="flex relative mt-1 md:mt-0">
            <div>
              <h3 className="w-[100%] font-medium text-[14px] leading-[17px] mt-6 md:w-2/3 md:text-lg lg:text-xl lg:mt-8 xl:w-[70%] xl:mt-8 xl:text-2xl xl:leading-[30px] 2xl:w-[75%] 2xl:text-[40px] 2xl:leading-[52px]">
                Ace Physics, Chemistry, and Mathematics in Just 6 Weeks with
                Proven Strategies and Comprehensive Resources!
              </h3>
              <button
                className="mt-4 py-2 px-8 text-xs rounded-lg shadow-lg bg-[linear-gradient(to_left,_#002F48,_#004062,_#00507B,_#006195,_#0072AE)] md:mt-6 md:text-lg xl:ml-3 xl:mt-12 xl:px-12 xl:py-4 xl:text-[2rem] 2xl:py-5 2xl:px-14"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/allcourses/explore/4");
                }}
              >
                Know More
              </button>
            </div>
            <img
              src={bannermobileImg}
              className="object-contain w-[110px] md:hidden"
              alt="Banner"
            />
          </div>
        </div>
        <div className="bannerImg w-2/5 h-full hidden md:flex md:h-fit">
          <img
            src={bannerImg}
            className="object-contain w-full h-full 2xl:w-[90%]"
            alt="Banner"
          />
        </div>
      </div>
      <div className="whyChoose pb-10 md:pb-0">
        <div className="relative flex flex-col items-center pb-11 xl:pb-20">
          <img
            src={whyChoosebannerImgMob}
            className="absolute z-0 w-full h-full object-cover lg:hidden block"
          />
          <img
            src={whyChoosebannerImg}
            className="absolute z-0 w-full h-full object-cover hidden lg:block"
          />
          <div className="flex flex-col items-center md:flex-row lg:justify-between lg:items-end xl:items-center">
            <div className="md:w-[60%] 2xl:w-[55%] flex justify-center md:block">
              <h1 className="font-bold z-10 relative text-2xl xl:text-6xl 2xl:text-[80px] 2xl:leading-[150%] leading-[150%] text-center pt-9 xl:pt-24 w-3/5 md:w-4/6 lg:w-3/6 xl:w-4/6 xl:leading-[150%] md:text-left md:ml-[5%] md:text-4xl md:leading-[120%] ">
                Why Choose
                <span className="text-[#F67F00] "> SG's MHT CET </span>
                Crash Course?
              </h1>
              <div className="whyChooseContent hidden grid-cols-9 gap-3 px-[5%] md:grid z-10 relative mt-5">
                <div className="content bg-[#fff] border-[2px] border-gray-300 gradient-border col-span-3 flex flex-col items-center text-center py-4 px-[12px] gap-3 xl:gap-6 rounded-[8px]">
                  <img src={expertFaculty} className="w-[35px] xl:w-[60px]" />
                  <div className="h-full xl:flex xl:flex-col xl:justify-between xl:gap-4 2xl:w-3/4">
                    <h4 className="text-xs xl:text-2xl font-semibold leading-[150%] text-[#f67f00]">
                      Expert Faculty
                    </h4>
                    <p className="text-[10px] leading-[150%] xl:text-base">
                      {" "}
                      Learn from industry-leading educators with over 14 years
                      of experience.
                    </p>
                  </div>
                </div>
                <div className="content bg-[#fff] border-[2px] border-gray-300 gradient-border col-span-3 flex flex-col items-center text-center py-4 px-[12px] gap-3 xl:gap-6 rounded-[8px]">
                  <img
                    src={comprehensiveCoverage}
                    className="w-[35px] xl:w-[80px]"
                  />
                  <div className="h-full xl:flex xl:flex-col xl:justify-between xl:gap-4 2xl:w-3/4">
                    <h4 className="text-xs xl:text-2xl font-semibold leading-[150%] text-[#f67f00]">
                      Comprehensive Coverage
                    </h4>
                    <p className="text-[10px] leading-[150%] xl:text-base">
                      {" "}
                      Tailored syllabus that ensures mastery over all key
                      topics.
                    </p>
                  </div>
                </div>
                <div className="content bg-[#fff] border-[2px] border-gray-300 gradient-border col-span-3 flex flex-col items-center text-center py-4 px-[12px] gap-3 xl:gap-6 rounded-[8px]">
                  <img src={dailyMockTest} className="w-[35px] xl:w-[80px]" />
                  <div className="h-full xl:flex xl:flex-col xl:justify-between xl:gap-4 2xl:w-3/4">
                    <h4 className="text-xs xl:text-2xl font-semibold leading-[150%] text-[#f67f00]">
                      Daily Mock Tests
                    </h4>
                    <p className="text-[10px] leading-[150%] xl:text-base">
                      {" "}
                      Simulate real exam conditions to build confidence and
                      accuracy.
                    </p>
                  </div>
                </div>
                <div className="content bg-[#fff] border-[2px] border-gray-300 gradient-border col-span-3 flex flex-col items-center text-center py-4 px-[12px] gap-3 xl:gap-6 rounded-[8px]">
                  <img
                    src={personalizedAttention}
                    className="w-[35px] xl:w-[80px]"
                  />
                  <div className="h-full xl:flex xl:flex-col xl:justify-between xl:gap-4 2xl:w-3/4">
                    <h4 className="text-xs xl:text-2xl font-semibold leading-[150%] text-[#f67f00]">
                      Personalized Attention
                    </h4>
                    <p className="text-[10px] leading-[150%] xl:text-base">
                      {" "}
                      Small batch sizes for individual doubt-solving sessions.
                    </p>
                  </div>
                </div>
                <div className="content bg-[#fff] border-[2px] border-gray-300 gradient-border col-span-6 flex flex-col items-center text-center py-4 px-[12px] gap-3 xl:gap-6 rounded-[8px]">
                  <img
                    src={stateOfArt}
                    className="w-[40px] xl:w-[80px] xl:mt-6"
                  />
                  <div className="h-full xl:flex xl:flex-col xl:justify-between xl:gap-4">
                    <h4 className="text-xs xl:text-2xl font-semibold leading-[150%] text-[#f67f00] xl:flex xl:justify-center">
                      <span className="xl:w-2/3 xl:block">
                        State-of-the-Art Study Material
                      </span>
                    </h4>
                    <p className="text-[10px] leading-[150%] xl:text-base xl:flex xl:justify-center">
                      <span className="xl:w-2/3 xl:block">
                        Access concise notes, formula sheets, and question banks
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <img
              src={whyChoosemobilebannerImg}
              className="relative mt-2 md:w-[40%] md:mr-[2%] md:object-contain lg:w-[35%] lg:mt-auto xl:hidden"
            />
            <img
              src={whyChooseDesktopbannerImg}
              className="hidden xl:block relative w-[40%] xl:mr-[2%] 2xl:w-[35%]"
            />
          </div>
        </div>
        <div className="whyChooseContent relative z-10 grid grid-cols-9 gap-3 px-[5%] md:hidden">
          <div className="content border-[2px] border-gray-300 gradient-border col-span-3 flex flex-col items-center text-center py-4 px-[12px] gap-6 rounded-[8px]">
            <img src={expertFaculty} className="w-[35px]" />
            <h4 className="text-xs font-semibold leading-[150%] text-[#f67f00]">
              Expert Faculty
            </h4>
            <p className="text-[10px] leading-[150%]">
              {" "}
              Learn from industry-leading educators with over 14 years of
              experience.
            </p>
          </div>
          <div className="content border-[2px] bg-white border-gray-300 gradient-border col-span-3 flex flex-col items-center text-center py-4 px-[12px] gap-6 rounded-[8px]">
            <img src={comprehensiveCoverage} className="w-[35px]" />
            <h4 className="text-xs font-semibold leading-[150%] text-[#f67f00]">
              Comprehensive Coverage
            </h4>
            <p className="text-[10px] leading-[150%]">
              {" "}
              Tailored syllabus that ensures mastery over all key topics.
            </p>
          </div>
          <div className="content border-[2px] bg-white border-gray-300 gradient-border col-span-3 flex flex-col items-center text-center py-4 px-[12px] gap-6 rounded-[8px]">
            <img src={dailyMockTest} className="w-[35px]" />
            <h4 className="text-xs font-semibold leading-[150%] text-[#f67f00]">
              Daily Mock Tests
            </h4>
            <p className="text-[10px] leading-[150%]">
              {" "}
              Simulate real exam conditions to build confidence and accuracy.
            </p>
          </div>
          <div className="content border-[2px] bg-white border-gray-300 gradient-border col-span-4 flex flex-col items-center text-center py-4 px-[12px] gap-6 rounded-[8px]">
            <img src={stateOfArt} className="w-[40px]" />
            <h4 className="text-xs font-semibold leading-[150%] text-[#f67f00]">
              State-of-the-Art Study Material
            </h4>
            <p className="text-[10px] leading-[150%]">
              {" "}
              Access concise notes, formula sheets, and question banks
            </p>
          </div>
          <div className="content border-[2px] bg-white border-gray-300 gradient-border col-span-5 flex flex-col items-center text-center py-4 px-[12px] gap-6 rounded-[8px]">
            <img src={personalizedAttention} className="w-[35px]" />
            <h4 className="text-xs font-semibold leading-[150%] text-[#f67f00]">
              Personalized Attention
            </h4>
            <p className="text-[10px] leading-[150%]">
              {" "}
              Small batch sizes for individual doubt-solving sessions.
            </p>
          </div>
        </div>
      </div>
      <div className="weOffer md:px-[5%] xl:px-[3.5rem]">
        <div className="relative">
          <img
            src={weOfferHeading1}
            className="absolute left-0 -top-3 w-16 md:hidden"
          />
          <img
            src={weOfferHeading2}
            className="absolute left-1/2 -top-3 w-4 ml-7 z-0 md:hidden"
          />
          <h1 className="relative z-10 text-center font-bold text-[2rem] leading-[38.5px] md:text-4xl xl:text-[5rem]">
            Courses We Offer
          </h1>
          <img
            src={weOfferHeading3}
            className="absolute -right-4 -top-3 w-16 md:hidden"
          />
        </div>

        <div className="courses flex flex-col items-center gap-6 mt-3 pb-5 md:flex-row md:mt-8 xl:mt-16 xl:gap-[3.5rem]">
          <div className="physics flex flex-col items-center w-2/3 px-8 py-[1.75rem] rounded-3xl md:px-5 md:py-5 xl:px-14 xl:py-8 flex-1">
            <img
              src={physics}
              className="w-full h-[9rem] object-cover md:h-full"
            />
            <h3 className="mt-4 text-xl font-medium leading-[34px] text-[#184F78] md:mt-2 xl:mt-8 xl:text-3xl">
              Physics
            </h3>
          </div>
          <div className="chemistry flex flex-col items-center w-2/3 px-8 py-[1.75rem] rounded-3xl md:px-5 md:py-5 xl:px-14 xl:py-8 flex-1">
            <img
              src={chemistry}
              className="w-full h-[9rem] object-cover md:h-full"
            />
            <h3 className="mt-4 text-xl font-medium leading-[34px] text-[#184F78] md:mt-2 xl:mt-8 xl:text-3xl">
              Chemistry
            </h3>
          </div>
          <div className="maths flex flex-col items-center w-2/3 px-8 py-[1.75rem] rounded-3xl md:px-5 md:py-5 xl:px-14 xl:py-8 flex-1">
            <img
              src={maths}
              className="w-full h-[9rem] object-cover md:h-full"
            />
            <h3 className="mt-4 text-xl font-medium leading-[34px] text-[#184F78] md:mt-2 xl:mt-8 xl:text-3xl">
              Maths
            </h3>
          </div>
        </div>
      </div>
      <div className="testimonial capitalize text-center mt-[60px] 2xl:mt-[98px]">
        <div className="md:flex md:justify-center">
          <h1 className="font-bold text-[2rem] leading-[2.12rem] md:w-2/4 md:text-4xl xl:w-2/3 xl:leading-[5.12rem] xl:text-[5rem] lg:w-2/5">
            Testimonial what our
            <span className="text-[#f67f00]"> students </span>say
          </h1>
        </div>

        <div className="testimonialContainer bg-[#FFF7F0] mt-5 pb-12 md:pb-10 xl:mt-10">
          <div className="innerContainer pt-6 xl:pt-7">
            <Slider {...settings} className="md:flex">
              <div className="FirstTestimonial md:flex-1">
                <div className="flex flex-col items-center">
                  <div className="imgContainer bg-[#F47B1E] rounded-full w-fit p-5 xl:p-9">
                    <img
                      src={GauravGhude}
                      alt="Gaurav Ghude"
                      className="w-[165px] h-[165px] xl:w-[310px] xl:h-[310px]"
                    />
                  </div>
                  <div className="w-4/5">
                    <p className="text-sm text-center pt-6 xl:pt-9 xl:text-2xl">
                      "SG helped me improve my scores by 30% in just 6 weeks!
                      The mock tests and doubt sessions were a game changer.”
                    </p>
                    <h4 className="font-semibold text-sm pt-6 xl:text-3xl">
                      – Gaurav Ghude, 96%
                    </h4>
                  </div>
                </div>
              </div>

              <div className="SecondTestimonial md:flex-1">
                <div className="flex flex-col items-center">
                  <div className="imgContainer bg-[#F47B1E] rounded-full w-fit p-5 xl:p-9">
                    <img
                      src={KunalChoudhary}
                      alt="Testimonial 2"
                      className="w-[165px] h-[165px] xl:w-[310px] xl:h-[310px]"
                    />
                  </div>
                  <div className="w-3/5 md:w-4/5 xl:w-3/5">
                    <p className="text-sm text-center pt-6 xl:pt-9 xl:text-2xl">
                      "Best crash course for MHT CET. Highly recommend SG for
                      their faculty and structured approach."
                    </p>
                    <h4 className="font-semibold text-sm pt-6 xl:text-3xl">
                      – Kunal Choudhary, 97%
                    </h4>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className="upcomingBatches lg:mt-16 xl:mt-[90px]">
        <h1 className="text-center text-xl font-semibold leading-[38px] mt-3 tracking-wider text-[#002F48] md:hidden">
          Upcoming Batches
        </h1>
        <img
          src={batchBanner}
          alt="Batch Banner"
          className="w-full mt-5 md:hidden"
        />
        <img
          src={batchesdetail1}
          alt="Batch Details 1"
          className="w-full mt-9 md:hidden"
        />
        <img
          src={batchesdetail2}
          alt="Batch Details 2"
          className="w-full mt-3 md:hidden"
        />
        <img
          src={batchesdetaildesktop}
          alt="Batch Details Desktop"
          className="w-full hidden md:block"
        />
      </div>
      <div className="timings mt-4 md:relative md:mb-[10rem] xl:mb-[18rem]">
        <h1 className="font-semibold text-2xl leading-6 ml-3 tracking-[1px] text-[#002F48] md:hidden">
          Timings
        </h1>
        <div className="timingContainer mt-2 md:flex md:flex-col md:items-end md:absolute md:w-full md:-top-[7rem] lg:-top-[9rem] xl:-top-[12rem]">
          <div className="container">
            9:00 AM – 12:00 PM: Conceptual Lectures
          </div>
          <div className="container">
            12:30 PM – 2:30 PM: Problem Solving Sessions
          </div>
          <div className="container">
            3:00 PM – 5:00 PM: Revision and Daily Tests
          </div>
        </div>
        <div className="flex flex-col items-center md:absolute md:items-start md:ml-8 md:-top-5 xl:ml-20 xl:-top-12">
          <div className="flex relative">
            <img
              src={timingBtn}
              className="absolute -left-[4.4rem] top-2 w-14 md:hidden"
            />
            <img
              src={timingBtnDesktop}
              className="absolute w-14 left-auto md:-right-[12rem] lg:-right-[19rem] xl:-right-[128%] 2xl:-right-[145%] xl:top-[8rem] xl:w-24 top-10 hidden md:block"
            />
            <p className="mt-6 xl:mt-[6rem] text-[12px] xl:text-2xl font-light leading-[150%] md:flex md:justify-center xl:ml-[2rem]">
              Limited seats Available!
            </p>
          </div>
          <button
            className="bg-[linear-gradient(to_left,_#002F48,_#004062,_#00507B,_#006195,_#0072AE)] text-[16px] py-3 px-9 xl:px-[122px] xl:py-6 md:px-16 md:text-xl xl:text-[2rem] xl:mt-5 rounded-lg xl:ml-3 mt-2 shadow-xl text-white"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/mht-cet-enrollment-form");
            }}
          >
            Enroll Today
          </button>
        </div>
      </div>
      <div className="specialFeatures mt-7 pt-4 pb-10 md:p-0 bg-[linear-gradient(to_left,_#2C92DE,_#184F78)]">
        <div className="flex justify-between">
          <div className="md:w-[70%] md:pt-4 md:pb-10 lg:pt-8">
            <h1 className="text-white text-xl md:text-4xl md:text-start md:px-6 xl:py-8 xl:px-20 font-bold text-center xl:text-[64px] leading-[150%] tracking-[1px]">
              Special Features:
            </h1>
            <div className="relative flex justify-center mt-6 md:hidden">
              <img src={SFBg1} className="absolute w-[129px] -left-16 -top-4" />
              <img src={SFBanner} className="w-[210px] relative z-10" />
              <img
                src={SFBg2}
                className="absolute w-[120px] bottom-0 -right-5"
              />
            </div>
            <div className="features mt-4 md:mt-12 lg:mt-16 px-6 flex flex-col gap-4 md:items-center xl:gap-9 xl:px-20">
              <div className="md:grid md:grid-cols-2 w-full md:gap-4 xl:gap-9 flex flex-col gap-4">
                <div className="feature">
                  <img src={featureCount1} className="w-12 xl:w-[60px]" />
                  <p>Topic-wise practice tests and mock exams.</p>
                </div>
                <div className="feature">
                  <img src={featureCount2} className="w-12 xl:w-[60px]" />
                  <p>Regular performance tracking and feedback.</p>
                </div>
              </div>

              <div className="feature md:w-1/2">
                <img src={featureCount3} className="w-12 xl:w-[60px]" />
                <p>
                  Exclusive guidance on time management and exam strategies.
                </p>
              </div>
            </div>
          </div>
          <img
            src={SFBannerDesktop}
            className="w-[30%] xl:w-[28%] h-full md:block hidden"
          />
        </div>
      </div>
      <div className="FAQ mt-4 md:flex md:items-center xl:px-12 xl:mt-10">
        <div className="flex justify-center 2xl:w-[40%]">
          <img src={FAQBanner} className="w-[335px] md:w-full lg:hidden" />
          <img
            src={FAQBannerDesktop}
            className="w-[335px] md:w-full hidden lg:block"
          />
        </div>
        <div className="faqContainer w-full 2xl:w-[60%] mx-auto bg-white p-4 md:pr-[5%]">
          {faqData.map((faq, index) => (
            <div
              key={index}
              className={`faqItem ${
                index === faqData.length - 1 ? "" : "border-b border-gray-300"
              }`}
            >
              <button
                className="w-full flex justify-between items-center py-3 xl:py-6 text-left text-black font-medium lg:text-lg xl:text-[32px]"
                onClick={() => toggleFAQ(index)}
              >
                {faq.question}
                <span className="text-lg lg:text-2xl xl:text-4xl">
                  {openIndex === index ? "−" : "+"}
                </span>
              </button>
              <div
                className={`faqAnswer overflow-hidden transition-all duration-300 ${
                  openIndex === index ? "max-h-screen py-2 xl:py-4" : "max-h-0"
                }`}
              >
                <p className="text-gray-600 text-sm lg:text-base xl:text-[28px]">
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MhtCetPage;
