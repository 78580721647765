import React, { useState } from "react";
import SGLogoMobile from "./assets/sgLogo.png";
import SGLogoDesktop from "./assets/sgLogoDesktop.png";
import "./EnrollmentForm.css";

function EnrollmentForm() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    address: "",
    school: "",
    gender: "",
    course: "MHT CET Crash Course",
    consent: false,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.fullName.trim()) newErrors.fullName = "Full Name is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
      newErrors.email = "Enter a valid email";
    if (!formData.mobile.trim()) newErrors.mobile = "Mobile Number is required";
    else if (!/^\d{10}$/.test(formData.mobile))
      newErrors.mobile = "Enter a valid 10-digit mobile number";
    if (!formData.address.trim()) newErrors.address = "Address is required";
    if (!formData.school.trim())
      newErrors.school = "School/College name is required";
    if (!formData.gender) newErrors.gender = "Gender selection is required";
    if (!formData.consent)
      newErrors.consent = "You must agree to the consent checkbox";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const sheetDbURL = "https://sheetdb.io/api/v1/med71cl0lwjp5";

      try {
        const response = await fetch(sheetDbURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          alert("Form submitted successfully!");
          setFormData({
            fullName: "",
            email: "",
            mobile: "",
            address: "",
            school: "",
            gender: "",
            course: "MHT CET Crash Course",
            consent: false,
          });
        } else {
          alert(
            "An error occurred while submitting the form. Please try again."
          );
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        alert("An error occurred. Please try again.");
      }
    } else {
      alert("Please fill all required fields correctly");
    }
  };

  return (
    <div className="bg-[#CEDDE6] px-4 min-h-screen pt-6 pb-3 md:px-16 lg:px-24 xl:px-[20%] 2xl:px-[18%]">
      <div className="heading flex items-center">
        <img src={SGLogoMobile} className="xl:hidden" />
        <img src={SGLogoDesktop} className="hidden xl:block xl:w-[85px]" />
        <h1 className="font-bold text-2xl lg:text-3xl leading-[120%] ml-6 md:text-center md:w-full md:ml-0 2xl:text-[64px]">
          Enrollment Form
        </h1>
      </div>
      <form
        onSubmit={handleSubmit}
        className="py-3 2xl:py-8 px-4 2xl:px-12 md:py-6 md:px-10 bg-white rounded-xl 2xl:mt-16 lg:mt-4"
      >
        <h2 className="text-[16px] 2xl:text-[30px] lg:text-xl font-bold mb-9 2xl:mb-14 text-center">
          Please fill the form below to join in SG
        </h2>

        <div className="formBox">
          <label className="block font-medium mb-1">
            Full Name<span className="text-[#FF1818]">*</span>:
          </label>
          <div className="w-full relative">
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              className="w-full px-3 py-3"
              placeholder="Enter Full Name"
            />
            {errors.fullName && (
              <p className="text-red-500 text-xs absolute ml-2">
                {errors.fullName}
              </p>
            )}
          </div>
        </div>

        <div className="formBox">
          <label className="block text-sm font-medium mb-1">
            E-Mail Id<span className="text-[#FF1818]">*</span>:
          </label>
          <div className="w-full relative">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-3"
              placeholder="Enter a valid E-mail"
            />
            {errors.email && (
              <p className="text-red-500 text-xs absolute ml-2">
                {errors.email}
              </p>
            )}
          </div>
        </div>

        <div className="formBox">
          <label className="block text-sm font-medium mb-1">
            Mobile No<span className="text-[#FF1818]">*</span>:
          </label>
          <div className="w-full relative">
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              className="w-full px-3 py-3"
              placeholder="Enter your mobile number"
            />
            {errors.mobile && (
              <p className="text-red-500 text-xs absolute ml-2">
                {errors.mobile}
              </p>
            )}
          </div>
        </div>

        <div className="mb-5 flex items-start 2xl:mb-14">
          <label className="w-1/2 2xl:w-[28%] font-medium text-base lg:text-lg 2xl:text-2xl">
            Address<span className="text-[#FF1818]">*</span>:
          </label>
          <div className="w-full relative ">
            <textarea
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="w-full lg:text-base px-3 py-2 border border-[#74A8C3] rounded text-sm 2xl:text-2xl 2xl:border-2 2xl:rounded-[15px]"
              placeholder="Enter Address"
              rows="3"
            ></textarea>
            {errors.address && (
              <p className="text-red-500 text-xs -mt-2 absolute ml-2">
                {errors.address}
              </p>
            )}
          </div>
        </div>

        <div className="formBox">
          <label className="block text-sm font-medium mb-1">
            School/College<span className="text-[#FF1818]">*</span>:
          </label>
          <div className="w-full relative">
            <input
              type="text"
              name="school"
              value={formData.school}
              onChange={handleChange}
              className="w-full px-3 py-3"
              placeholder="Enter your School/College name"
            />
            {errors.school && (
              <p className="text-red-500 text-xs absolute ml-2">
                {errors.school}
              </p>
            )}
          </div>
        </div>

        <div className="formBox">
          <label>
            Gender<span className="text-[#FF1818]">*</span>:
          </label>
          <div className="w-full relative">
            <div className="flex items-center space-x-4 w-full">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="Male"
                  checked={formData.gender === "Male"}
                  onChange={handleChange}
                  className="mr-2"
                />
                Male
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="Female"
                  checked={formData.gender === "Female"}
                  onChange={handleChange}
                  className="mr-2"
                />
                Female
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value="Other"
                  checked={formData.gender === "Other"}
                  onChange={handleChange}
                  className="mr-2"
                />
                Other
              </label>
            </div>

            {errors.gender && (
              <p className="text-red-500 text-xs -mt-1 absolute ml-2">
                {errors.gender}
              </p>
            )}
          </div>
        </div>

        <div className="formBox">
          <label className="block text-sm font-medium mb-1">
            Course<span className="text-[#FF1818]">*</span>:
          </label>
          <input
            type="text"
            value="MHT CET Crash Course"
            disabled
            className="w-full border rounded px-3 py-3 bg-gray-100"
          />
        </div>

        <div className="mb-5">
          <div className="w-full relative">
            <label className="flex items-start text-xs lg:text-lg 2xl:text-2xl">
              <input
                type="checkbox"
                name="consent"
                checked={formData.consent}
                onChange={handleChange}
                className="mr-4 mt-[1px] lg:mt-[6px] 2xl:mt-[8px]"
              />
              I confirm to allow the instructor call or Message to proceed with
              the enrollment process.
            </label>

            {errors.consent && (
              <p className="text-red-500 text-xs absolute ml-7">
                {errors.consent}
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-center mb-9 md:mt-10">
          <button
            type="submit"
            className="w-fit bg-[#F67F00] text-white text-[16px] rounded px-14 py-3 font-medium"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default EnrollmentForm;
