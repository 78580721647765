import React from "react";
import { Facebook, Twitter, Instagram, Youtube } from "lucide-react";
// import { Brain, Rocket, User2, BookOpenCheck, ArrowUp } from "lucide-react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Boy from "../../images/freepik__a-young-boy-with-red-hair-wearing-a-white-shirt-an__76147 1.png";
import SGLogo from "../../images/SG Logo SVG 3.png";
import Brain from "../../images/Vector.png";
import Rocket from "../../images/Vector (1).png";
import Man from "../../images/Vector (2).png";
import Robo from "../../images/eos-icons_machine-learning-outlined.png";
import Arrow from "../../images/Vector (3).png";
import { useNavigate } from "react-router-dom";

// Custom Button Component
const CustomButton = ({ children, className, ...props }) => {
  return (
    <button
      className={`inline-flex items-center gap-2 font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ${className}`}
      {...props}
    >
      <span>Register now</span>
      <ChevronRight className="w-5 h-5" strokeWidth={2} />
    </button>
  );
};

export default function MathsWorkshopFix({ eventId }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
        <div className="container mx-auto px-4">
          <div className="grid gap-8 md:gap-12 lg:gap-16">
            <div className="w-full shadow-custom bg-white rounded-2xl border-[3px] border-[#003147] overflow-hidden">
              <div className="bg-[#003147] p-4 md:p-6 lg:p-8 rounded-t-xl relative">
                <div className="bg-white w-20 h-20 sm:w-24 sm:h-24 lg:w-36 lg:h-36 xl:w-36 xl:h-36 rounded-full absolute -top-2 -left-2 flex items-center justify-center">
                  <img
                    src={SGLogo}
                    alt="SG Education Logo"
                    className="w-[57%] h-[57%] object-contain"
                  />
                </div>
                <div className="text-center ml-20 sm:ml-24 lg:ml-36 xl:ml-40 tracking-widest">
                  <h1 className="text-xl sm:text-2xl lg:text-5xl xl:text-5xl font-bevan mb-1 sm:mb-2 lg:mb-3 text-white">
                    AFRAID OF
                    <sapn className="text-xl pl-2 sm:text-2xl lg:text-5xl xl:text-5xl font-bevan mb-0 text-[#F67F00]">
                      MATHS?
                    </sapn>
                  </h1>
                </div>
              </div>

              <div className="relative p-4 sm:p-6 lg:p-10 xl:p-12">
                <div className="flex flex-col justify-end gap-2 sm:gap-3 lg:gap-6 xl:gap-8">
                  <p className="text-gray-700 text-xs sm:text-sm lg:text-xl xl:text-2xl">
                    Starts from{" "}
                    <span className="font-medium">December 26, 2024</span>
                  </p>

                  <p className="text-gray-900 font-medium text-base sm:text-base lg:text-2xl xl:text-4xl">
                    Strengthen Your Maths Skills And Overcome
                    <br className="hidden sm:block" />
                    Fear Of Numbers
                  </p>

                  <p className="text-gray-600 text-xs sm:text-sm lg:text-xl xl:text-2xl">
                    @KalyanCenter
                  </p>

                  <div className="relative z-10">
                    <CustomButton
                      onClick={() => navigate(`/event/register/${eventId}`)}
                      className="bg-[#F67F00] text-white px-3 sm:px-4 lg:px-8 xl:px-10 py-2 lg:py-3 rounded-lg hover:bg-[#e65f00] transition-colors inline-flex items-center gap-1 sm:gap-2 text-xs sm:text-sm lg:text-xl xl:text-2xl"
                    >
                      Register now{" "}
                      <ChevronRight
                        className="w-3 h-3 sm:w-4 sm:h-4 lg:w-6 lg:h-6 xl:w-7 xl:h-7"
                        strokeWidth={2}
                      />
                    </CustomButton>
                  </div>

                  <div className="space-y-4 sm:space-y-6 lg:space-y-8">
                    <div>
                      <p className="text-[#003153] text-sm sm:text-base lg:text-xl xl:text-2xl mb-2 sm:mb-3 lg:mb-4">
                        Share on
                      </p>
                      <div className="flex gap-3 sm:gap-4 lg:gap-6">
                        {[
                          { Icon: Facebook, href: "#" },
                          { Icon: Youtube, href: "#" },
                          { Icon: Instagram, href: "#" },
                        ].map(({ Icon, href }, index) => (
                          <a
                            key={index}
                            href={href}
                            className="w-8 h-8 sm:w-10 sm:h-10 lg:w-14 lg:h-14 xl:w-12 xl:h-12 rounded-full border-2 border-[#003153] flex items-center justify-center hover:bg-[#003153]/5 transition-colors"
                          >
                            <Icon className="w-4 h-4 sm:w-5 sm:h-5 lg:w-8 lg:h-8 xl:w-7 xl:h-7 text-[#003153]" />
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <img
                  src={Boy}
                  alt="Student studying"
                  className="w-[40%] sm:w-1/4 lg:w-1/3 xl:w-[30%] h-auto absolute bottom-0 right-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Header Section */}
      <div className="w-full">
        {/* Orange Section */}
        <div className="bg-[#F67F00] w-full">
          <div className="container mx-auto px-4 py-8">
            <div className="flex flex-col md:flex-row items-center justify-between gap-8">
              {/* Start Date */}
              <div className="text-[#003249] text-xl md:text-2xl font-bold">
                Starts from <span className="text-white">December 26</span>
              </div>

              {/* Price Tag */}
              <div className="bg-[#003249] text-white px-6 sm:px-8 py-3 sm:py-4 rounded-full shadow-lg">
                <div className="text-center">
                  <div className="text-lg sm:text-xl md:text-2xl font-medium">
                    Complete Workshop @
                  </div>
                  <div className="text-xl sm:text-2xl md:text-3xl font-bold">
                    Rs. 1500/- Only
                  </div>
                </div>
              </div>

              {/* Timings */}
              <div className="text-center">
                <div className="text-[#003249] text-xl md:text-2xl font-bold mb-2">
                  Timings
                </div>
                <div className="text-[#003249] font-medium text-sm sm:text-base">
                  <div>
                    Khadakpada Branch:{" "}
                    <span className="text-white">10 AM - 12 PM</span>
                  </div>
                  <div>
                    Prem Auto Branch:{" "}
                    <span className="text-white">4 PM - 6 PM</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Key Benefits Section */}
        <div className="container mx-auto px-8 py-12 sm:py-16 w-full sm:w-[90%] md:w-[80%]">
          <h2 className="text-[#003249] text-3xl sm:text-4xl md:text-5xl font-bold text-center mb-10 sm:mb-16">
            Key Benefits
          </h2>

          <div className="bg-[#F67F00] rounded-3xl p-6 sm:p-8">
            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-6 sm:gap-8 text-center">
              {[
                { icon: Brain, text: "Simplified Concepts" },
                { icon: Rocket, text: "Boost Your Confidence" },
                { icon: Man, text: "Expert Guidance" },
                { icon: Robo, text: "Fun Learning Techniques" },
                { icon: Arrow, text: "Gear Up for your Future Learning" },
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center gap-3 sm:gap-4"
                >
                  <img
                    src={item.icon}
                    alt={item.text}
                    className="w-12 h-12 sm:w-16 sm:h-16"
                  />
                  <div className="text-white text-sm sm:text-base md:text-lg font-medium">
                    {item.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="container mx-auto px-4 my-8 sm:my-12">
          <div className="grid md:grid-cols-2 gap-8 sm:gap-16 md:gap-40 max-w-4xl mx-auto">
            {/* Topics Covered */}
            <div>
              <h2 className="text-[#F67F00] text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6">
                Topics Covered:
              </h2>
              <ul className="space-y-2 sm:space-y-3 text-[#003249] text-base sm:text-lg list-disc pl-5 font-bold">
                <li>Number & Quick Calculations</li>
                <li>Algebra made easy</li>
                <li>Geometry Simplified</li>
                <li>Data Handling with ease</li>
                <li>Logical thinking & Problem solving</li>
              </ul>
            </div>

            {/* Special Benefits */}
            <div>
              <h2 className="text-[#F67F00] text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6">
                Special Benefits:
              </h2>
              <ul className="space-y-2 sm:space-y-3 text-[#003249] text-base sm:text-lg list-disc pl-5 font-bold">
                <li>Free Study Material: Worksheets & Notes</li>
                <li>Personalized attention Guaranteed</li>
              </ul>
            </div>
          </div>

          {/* Register Button */}
          <div className="flex justify-center mt-10 sm:mt-16">
            <CustomButton
              onClick={() => navigate(`/event/register/${eventId}`)}
              className="bg-[#F67F00] text-white px-6 sm:px-8 py-2 sm:py-3 rounded-md text-base sm:text-lg font-medium hover:bg-[#FF8000]/90 transition-colors"
            >
              Register now
            </CustomButton>
          </div>
        </div>

        {/* Contact Footer */}
        <div className="bg-[#F67F00] py-3 sm:py-4 text-center">
          <p className="text-[#003249] text-base sm:text-lg font-bold">
            For more details, Contact @
            <span className="text-white">8591932112</span>
          </p>
        </div>
      </div>
    </>
  );
}
